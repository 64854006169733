<template>
  <Dialog v-model:visible="visible" :style="{width: '450px'}" header="Question details" :modal="true" class="grid p-fluid">
    <div class="col-12 md:col-12">
      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="title">Title</label>
          <InputText id="title" v-model.trim="entityData.title" required="true" autofocus :class="{'p-invalid': submitted && !entityData.title}" />
          <small class="p-invalid" v-if="submitted && !entityData.title">Title is required.</small>
        </div>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="code">Code</label>
          <InputText id="code" v-model.trim="entityData.code" required="true" autofocus :class="{'p-invalid': submitted && !entityData.code}" />
          <small class="p-invalid" v-if="submitted && !entityData.code">Code is required.</small>
        </div>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="input_type_id" class="mb-3">Input type</label>
          <Dropdown id="input_type_id" v-model="entityData.input_type_id" :options="inputTypes" option-label="title" placeholder="Select a type" option-value="id" @change="setSelectedInputType">
          </Dropdown>
        </div>
      </div>

      <div class="card" v-if="entityData.input_type && entityData.input_type.title==='select'">
        <div class="grid">
          <div class="col-12 mb-2 lg:col-12 lg:mb-0">
            <OrderList v-model="dropdownValues" listStyle="height:250px" dataKey="id" :rows="25" v-model:selection="selection">
              <template #header>
                Values
              </template>
              <template #item="slotProps">
                <div>
                  {{slotProps.item.name}}
                </div>
              </template>
              <template #controlsend>
                <Button icon="pi pi-times" class="p-button-text" @click="removeOption" />
              </template>
            </OrderList>
          </div>
        </div>
        <div class="grid" v-if="entityData.input_type && entityData.input_type.title==='select'">
          <div class="col-12 md:col-4">
            <label for="optionLabel">Label</label>
            <InputText id="optionLabel" v-model.trim="optionLabel" autofocus/>
          </div>
          <div class="col-12 md:col-4">
            <label for="optionValue">Value</label>
            <InputText id="optionValue" v-model.trim="optionValue" autofocus/>
          </div>
          <div class="col-12 mt-3 md:col-4">
            <Button label="Add" icon="pi pi-check" class="p-button-text" @click="addOption" />
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="value">Value</label>
          <InputText id="value" v-model.trim="entityData.value" autofocus/>
        </div>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="tooltip">Tooltip</label>
          <InputText id="tooltip" v-model.trim="entityData.tooltip" autofocus/>
        </div>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="placeholder">Placeholder</label>
          <InputText id="placeholder" v-model.trim="entityData.placeholder" autofocus/>
        </div>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="price">Price</label>
          <InputText id="price" v-model.trim="entityData.price" autofocus/>
        </div>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="help_text">Help text</label>
          <InputText id="help_text" v-model.trim="entityData.help_text" autofocus/>
        </div>
      </div>

      <div class="grid">
        <div class="col-12 md:col-6">
          <Checkbox class="m-1" id="visible" name="option" value="1" :binary="true" v-model="entityData.visible" />
          <label for="visible">Visible</label>
        </div>
        <div class="col-12 md:col-6">
          <Checkbox class="m-1" id="readonly" name="option" value="1" :binary="true" v-model="entityData.readonly" />
          <label for="readonly">Readonly</label>
        </div>
      </div>

      <div class="grid">
        <div class="col-12 md:col-6">
          <Checkbox class="m-1" id="disabled" name="option" value="1" :binary="true" v-model="entityData.disabled" />
          <label for="disabled">Disabled</label>
        </div>
        <div class="col-12 md:col-6">
          <Checkbox class="m-1" id="required" name="option" value="1" :binary="true" v-model="entityData.required" />
          <label for="required">Required</label>
        </div>
      </div>
    </div>

    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="$emit('hideDialog')"/>
      <Button label="Save" icon="pi pi-check" class="p-button-text" @click="save" />
    </template>
  </Dialog>
</template>

<script>

import InputTypeService from "@/service/InputTypeService";
import QuestionService from "@/service/QuestionService";
import _findIndex from 'lodash/findIndex';
import _pullAt from 'lodash/pullAt';

export default {
  name: "QuestionForm",
  emits: ['hideDialog','saved'],
  props: {
    entity: Object,
    dialog: Boolean
  },
  inputTypeService: null,
  entityService: null,
  mounted() {
    this.inputTypeService.fetchAll().then(data => {
      this.inputTypes = data.data;
    });
  },

  computed: {
    visible: {
      get() { return this.dialog },
      set(visible) {this.$emit('hideDialog', visible)}
    },
    entityData: {
      get() { return this.entity },
    },
    dropdownValues: {
      get() {
        return this.entity.config.values ?? []
      },
      set(values) {
        this.entityData.config.values = values;
      }
    },
  },

  created() {
    this.inputTypeService = new InputTypeService();
    this.entityService = new QuestionService();
  },

  data() {
    return {
      submitted: false,
      inputTypes: [],
      optionLabel: '',
      optionValue: '',
      selection: [],
    }
  },

  methods: {
    save() {
      this.submitted = true;

      if (this.entityData.input_type.title !== 'select') {
        delete this.entityData.config;
      }

      if (this.entity.id) {
        this.entityService.update(this.entityData).then(response => {
          this.$toast.add({severity:'success', summary: 'Success', detail: 'Question updated', life: 3000});
          this.$emit('saved', response);
        });
      } else {
        this.entityService.create(this.entityData).then(response => {
          this.$toast.add({severity:'success', summary: 'Success', detail: 'Question created', life: 3000});
          this.$emit('saved', response);
        });
      }
    },

    addOption() {
      if (!Array.isArray(this.entityData.config.values)) {
        this.entityData.config.values = [];
      }

      this.entityData.config.values.push({
        id: this.optionValue,
        name: this.optionLabel
      });

      this.optionLabel = '';
      this.optionValue = '';
    },

    removeOption() {
      for (const selection of this.selection) {
        const index = _findIndex(this.entityData.config.values, function (item) {
          return item.id === selection.id;
        })
        _pullAt(this.entityData.config.values, index);
      }
    },

    setSelectedInputType(event) {
      for (const inputType of this.inputTypes) {
        if (inputType.id === event.value) {
          this.entityData.input_type = inputType;
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
