import http from "@/service/HttpService";
import axios from "axios";

export default class InputTypeService {
    fetchAll() {
        return http.get(`input-type?per_page=300`).then(d => d.data);
    }

    create(entity) {
        return http.post(`input-type`, entity).then(response => response.data);
    }

    update(entity) {
        return http.put(`input-type/${entity.id}`, entity).then(response => response.data);
    }

    delete(entity) {
        return http.delete(`input-type/${entity.id}`).then(response => response.data);
    }

    bulkDelete(entities) {
        const requests = [];
        for(const entity of entities) {
            requests.push(http.delete(`input-type/${entity.id}`));
        }

        return axios.all(requests).then()
    }
}
